<!--
 * @Descripttion: 评审专业
 * @version: 1.0
 * @Company: ZYZN
 * @Author: Niklaus
 * @Date: 2021-01-07 16:27:40
-->
<template>
	<div class="vh bg">
		<van-nav-bar title="评审专业" left-arrow @click-left="goBack" class="myNav"
		 :class="{textR: routeIndex ==0}" />
		<van-search v-model="searchV" @search="doSearch" placeholder="请输入搜索关键词" show-action shape="round" v-show="showSearch"
		 @clear="doClear">
			<template #action>
				<p class="fc" @click="doSearch">搜索</p>
			</template>
		</van-search>

		<div class="flex-around whiteBg" style="padding-top: 0.2rem" v-show="showTitle">
			<h3 :class="{f5:true,title : true,'active-title': activeTitle ==0}" @click="activeTitle = 0">工程类</h3>
			<h3 :class="{f5:true,title : true,'active-title': activeTitle ==1}" @click="activeTitle = 1">货物类</h3>
			<h3 :class="{f5:true,title : true,'active-title': activeTitle ==2}" @click="activeTitle = 2">服务类</h3>
		</div>
		<div class="w-line"></div>
		<router-view :activeIndex="activeTitle" :routeIndex="routeIndex" ref="child" @onTitle="onTitle" @onShowSearch="onShowSearch"
		 @onRouteIndex="onRouteIndex" class="whiteBg" />
	</div>
</template>

<script>
	import {
		Search,
		Dialog
	} from 'vant'
	export default {
		data() {
			return {
				searchV: '',
				activeTitle: 0,
				// 是否显示一级大类， 默认显示
				showTitle: true,
				// 是否显示搜索功能
				showSearch: true,
				// 判断是哪个路由
				routeIndex: 0

			}
		},

		components: {
			[Search.name]: Search,
			[Dialog.name]: Dialog
		},

		methods: {
			goBack() {
				if (this.$route.path.indexOf("info") >= 0)
					this.$router.push('/mine')
				else
					this.$router.push('/profession/info')
			},
			doSearch() {
				this.$refs.child.doSearch(this.searchV)
			},
			//清除
			doClear(event) {
				// this.searchV ='';
				// this.$refs.child.doClear();
			},
			/**
			 * @Description: 是否显示一级大类
			 * @Author: Niklaus
			 * @Date: 2021-01-28 16:31:16
			 */
			onTitle(showTitle) {
				this.showTitle = JSON.parse(showTitle)
			},

			/**
			 * @Description: 是否显示搜索功能
			 * @Author: Niklaus
			 * @Date: 2021-01-28 16:43:10
			 * @param {*} showSearch
			 */
			onShowSearch(showSearch) {
				this.showSearch = JSON.parse(showSearch)
			},

			onRouteIndex(index) {
				if (index == 0) this.activeTitle = 0
				this.routeIndex = index
			},

			resetRemark() {
				let _this = this
				this.$dialog.confirm({
					title: '重置全部备注',
					message: '将全部重置专业备注！是否仍然继续操作？'
				}).then(() => {
					_this.$refs.child.resetRemark()
				});
			}
		}
	}
</script>

<style scoped>
	.flex-item {
		margin-top: 0.2rem;
		position: relative;
		padding: 0.1rem 0.5rem
	}

	.myNav .van-nav-bar__right {
		display: none;
	}

	.pad-t3 {
		padding-top: 0.3rem;
	}
</style>
